(function ($, root, undefined) {

	$(function () {

		'use strict';
		var $navHolder = $('.navholder');

		//------------------------------------
		//    Activate full screen menu
		//------------------------------------
		$('body').on('click', '.hamburger.active', function(e){
			$('.hamburger').removeClass('active');
			e.preventDefault();
			if($('body').hasClass('active-nav')){
					$('body').removeClass('active-nav');
					setTimeout(function(){
						$navHolder.css({display: 'none'});
						$('.hamburger').addClass('active');
					}, 400);
			} else{
					$navHolder.css({display: 'block'});
					setTimeout(function(){
						$('body').addClass('active-nav');
						$('.hamburger').addClass('active');
						if($('body.desktop').length < 1 && $('header.header.scrolled').length > 0){
							setTimeout(function(){
								$('header.header.scrolled').removeClass('scrolled');
								$('.scrollheight').hide();
							}, 100);
						}
					}, 100);
			}
		});

		$('.tab', '.anchor-tabs').on('click', function (event) {
			event.preventDefault();
			var $obj = $(this);
			var hash = $obj.attr('href');
			var $id = hash.replace('#', '');
			var $anchor_icons = $('.anchor-icon');
			var $clicked_anchor = $anchor_icons.filter(function (index, element) {
				var $element = $(element);
				return $element.attr('id') === $id;
			});
			goToByScroll($clicked_anchor);
		});

		//------------------------------------
		//    Search form
		//------------------------------------
		var searchForm = $('.header form');
		var searchInput = $('.search input');
		$('body').on('click', '.search button', function(e){
			if(searchForm.hasClass('active')){
				if(searchInput.val().length < 1){
					searchForm.removeClass('active');
					e.preventDefault();
				}
			} else{
				searchForm.addClass('active');
				setTimeout(function(){
					searchForm.find('input').focus();
				}, 400);
				e.preventDefault();
			}
		});

		$('body').on('blur', '.search input', function(e){
			if(searchInput.val().length < 1){
				setTimeout(function(){
					searchForm.removeClass('active');
				}, 200);
			}
		});

		//------------------------------------
		//    Breadcrumbs
		//------------------------------------
		function getQueryVariable(variable)
		{
					 var query = window.location.search.substring(1);
					 var vars = query.split("&");
					 for (var i=0;i<vars.length;i++) {
									 var pair = vars[i].split("=");
									 if(pair[0] == variable){return pair[1];}
					 }
					 return(false);
		}

		function goToByScroll(div){
				var offset = $(div).offset();
				var scrolltoY = offset.top;

				$('html,body').animate({ scrollTop: scrolltoY - 120 }, 500);
		}

		$('body').on('click', '.onpagelink', function(e){
			e.preventDefault();
			var elem = $('.' + $(this).data('slug'));
			if(elem.length > 0){
					goToByScroll(elem);
			}
		});

		function checkThisBreadCrumb(name){
			var scrollobject = $('.' + name);
			var scrollOffset = scrollobject.offset();
			if(typeof scrollOffset !== "undefined"){
				var scrollTop = scrollOffset.top;
				var scroll = $(window).scrollTop();

				if((scrollTop) < (($(window).height() * 0.50) + scroll)){
					return true;
				} else{
					return false;
				}
			} else{
				return false;
			}
		}

		var areasToWatch = new Array();

		function createBreadcrumbWatcher(){
			var $breadcrumbs = $('.header .breadcrumbs');
			$breadcrumbs.find('a').each(function(){
				if(typeof $(this).data('slug') !== "undefined"){
					areasToWatch.push($(this).data('slug'));
				}
			});
		}

		function breadcrumbsScroll(){
			var shownAreas = new Array();
			for(var i = areasToWatch.length - 1; i >= 0; i--){
				if(checkThisBreadCrumb(areasToWatch[i])){
					shownAreas.push(areasToWatch[i]);
				}
			}

			if(shownAreas.length < 1){
				shownAreas.push(areasToWatch[0]);
			}

			$('.header .breadcrumbs').find('a').each(function(){
				$(this).removeClass('active');
			});

			$('.header').find('.link-' + shownAreas[0]).addClass('active');
		}

		//End Breadcrumbs

		//------------------------------------
		//    Sticky menu on scroll
		//------------------------------------

		function checkMenuScroll(){
			var header = $('.header');
			var scroll = $(window).scrollTop();

			if(scroll >= 35){
					if(header.hasClass('scrolled')){

					} else{
						header.addClass('scrolled');
						$('.scrollheight').show();
					}
				}
			if(scroll < 35){
					header.removeClass('scrolled');
					$('.scrollheight').hide();
				}
		}
		//End Sticky menu on scroll


		//------------------------------------
		//    Search form
		//------------------------------------
		var scrollingDone;

		$(window).scroll(function (event) {
			if($('body.active-nav').length < 1){
					checkMenuScroll();
			}
		});

		$(document).ready(function(){
			createBreadcrumbWatcher();

			setTimeout(function(){
				$('body').removeClass('preanimation');
			}, 100);

			if($('.header .breadcrumbs').length > 0){
				$(window).scroll(function(){
					clearTimeout(scrollingDone);
					scrollingDone = setTimeout(breadcrumbsScroll, 100);
				});
				breadcrumbsScroll();
			}
			checkMenuScroll();
			if(getQueryVariable('section')){
				var regexp = /^[a-zA-Z0-9-_]+$/;
				var check = getQueryVariable('section');
				if (check.search(regexp) == -1)
				    return false;
				else
		    {
					if($('.slug-' + check).length > 0){
						goToByScroll('.slug-' + check);
					}
				}
			}
		});

		//------------------------------------
		//    Check if image should have padding on top
		//		Function only for Text Template Page
		//------------------------------------
		if($('.page-template-template-textsida').length > 0){
			$('.col1-3.imagecolumn-toppadding').each(function(){
				var $this = $(this);
				var textcol = $this.parent('.template-columnholder').find('.col2-3');
				if(textcol.find('h1').length < 1){
					$this.removeClass('imagecolumn-toppadding');
				}
			});
		}

		//------------------------------------
		//    Contact navigation
		//------------------------------------
		var $selectwrapper = $('.selectwrapper');
		var displayBox = $('.selectbox .display');

		$('.selectbox').on('click', function(){
			$selectwrapper.toggleClass('active');
		});

		$('body.kontakt').on('click', function(){
			if($('.selectwrapper:hover').length < 1){
				$selectwrapper.removeClass('active');
			}
		});

		$selectwrapper.on('click', 'li', function(){
			var $this = $(this);
			$this.addClass('active').siblings('li').removeClass('active');
			var title = $this.text();
			var boxToShow = $this.data('cat');
			displayBox.text(title);
			$selectwrapper.removeClass('active');
			$('.' + boxToShow).show().siblings('li').hide();
			goToByScroll('.selectwrapper');
		});

		//------------------------------------
		//    Cookie policy
		//------------------------------------

		function setCookie(cname, cvalue, exdays) {
				var d = new Date();
				d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
				var expires = "expires="+d.toUTCString();
				document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
		}

		function getCookie(cname) {
				var name = cname + "=";
				var ca = document.cookie.split(';');
				for(var i = 0; i < ca.length; i++) {
						var c = ca[i];
						while (c.charAt(0) == ' ') {
								c = c.substring(1);
						}
						if (c.indexOf(name) == 0) {
								return c.substring(name.length, c.length);
						}
				}
				return "";
		}

		$('.cookiepolicy').on('click', 'button', function(e){
			e.preventDefault();
			//localStorage.setItem('cookieAccepted', true);
			setCookie('cookieAccepted', true, 1000);
			$('.cookiepolicy').remove();
		});

		$(document).ready(function(){
			var cookieAccepted = false;
			if(getCookie('cookieAccepted', true)){
				cookieAccepted = getCookie('cookieAccepted');
			}

			if(cookieAccepted){
				$('.cookiepolicy').remove();
			} else{
				$('.cookiepolicy').show();
			}

		});

		//------------------------------------
		//    IE9 CSS3 Incompability
		//------------------------------------

		setTimeout(function(){
			$('.animatedintro').animate({opacity: 1});
		}, 1400);

		//------------------------------------
		//    FAQ
		//------------------------------------

		if($('.page-template-template-faq').length > 0){
				$('body').on('click', '.question', function(){
					$(this).parent('.singlequestion').toggleClass('active');
				});
		}


	});

})(jQuery, this);
